import React from 'react';
import Head from 'next/head';
import withStores from '@studionand/ada-next/src/stores/withStores';
import { Observer } from 'mobx-react';
import EnplusLayout from '@studionand/depi/src/components/enplus/Layout';
import Login from '@studionand/depi/src/components/enplus/Login';

const EnplusPelletsLoginPage = props => {
  const handleSubmitLogin = ({ username, password }) => {
    props.loginStore.loginAsync(username, password);
  };
  return (
    <EnplusLayout context="hackschnitzel">
      <Head>
        <title>ENplus Hackschnitzel - Anmelden</title>
      </Head>
      <Observer>
        {() => (
          <Login
            onLogin={handleSubmitLogin}
            status={props.loginStore.status}
            isLoggedIn={props.loginStore.isLoggedIn}
            user={props.loginStore.currentUser}
          />
        )}
      </Observer>
    </EnplusLayout>
  );
};

export default withStores('loginStore')(EnplusPelletsLoginPage);
